import { Injectable } from "@angular/core";
import { ChatOpenStore } from "app/chat/open";
import { OutgoingMessageService } from "app/chat/outgoing";
import { map, merge, Observable, take, tap } from "rxjs";
import { AppHostService } from "./app-host.service";

/**
 * This service handles the events sent by external clients via the loader.
 *
 * Every event type has an associated Observable that executes the corresponding handler action.
 *
 * The get method allListeners$ is designed to return a merged Observable of all the listeners.
 * When adding a new event type, update this method to include the new listener.
 */
@Injectable({
  providedIn: "root",
})
export class ExternalEventsService {
  constructor(
    private appHost: AppHostService,
    private outgoingMessageService: OutgoingMessageService,
    private chatOpenStore: ChatOpenStore
  ) {}

  /**
   * Observable that listens to the entrypoint event and calls its handler function.
   */
  public get entrypoint$(): Observable<void> {
    return this.appHost.entrypoint$.pipe(
      map((entrypoint) => {
        if (entrypoint) {
          this.onEntrypoint(entrypoint);
        }
        return;
      })
    );
  }

  /**
   * Handler function for the entrypoint event.
   *
   * Sends a set entrypoint message to the backend then opens the chat if it is not already open.
   * @param entrypoint The entrypoint node to send.
   */
  private onEntrypoint(entrypoint: string): void {
    this.outgoingMessageService.sendEntrypointMessage(entrypoint);

    this.chatOpenStore.isOpen$
      .pipe(
        tap((isOpen) => {
          if (!isOpen) {
            this.chatOpenStore.open();
          }
        }),
        take(1)
      )
      .subscribe();
  }

  /**
   * Returns a merged Observable of all the listeners.
   *
   * Update this method to include new listeners.
   */
  public get allListeners$(): Observable<void> {
    return merge(this.entrypoint$);
  }
}
