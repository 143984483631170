import { Inject, Injectable } from "@angular/core";
import { ChatLifecycleService, ChatOpenStore } from "app/chat";
import { AppParentService } from "app/core";
import { Observable, take, tap } from "rxjs";
import { AVAILABILITY_STORE, AvailabilityState, AvailabilityStore } from "./availability";
import { ExternalEventsService } from "app/core/external-events.service";

/** A service used to retrieve and interact with all the states of the application */
@Injectable({ providedIn: "root" })
export class AppStateService {
  constructor(
    @Inject(AVAILABILITY_STORE) private availabilityStore: AvailabilityStore,
    private chatLifecycleService: ChatLifecycleService,
    private parentService: AppParentService,
    private chatOpenStore: ChatOpenStore,
    private externalEventsService: ExternalEventsService
  ) {}

  /** Initializes all the states in the application, for the states that are required */
  public initialize(): void {
    this.availabilityStore.initialize();
    this.chatLifecycleService.initialize();
    this.chatOpenStore.isOpen$
      .pipe(
        take(1),
        tap((isOpen) => this.parentService.setBotVisible(isOpen))
      )
      .subscribe();

    this.externalEventsService.allListeners$.subscribe();
  }

  /** @return Whether the application is available for the current user */
  get available$(): Observable<AvailabilityState> {
    return this.availabilityStore.available$;
  }
}
