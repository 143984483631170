import { Component, HostBinding, Inject, OnInit } from "@angular/core";

import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AppStateService, AvailabilityState } from "./app-state";
import { APP_CONFIG, AppConfig, AppParentService } from "./core";

import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { filter, tap } from "rxjs";
import { ChatLifecycleService, ChatOpenStore } from "./chat";
import { ChatBoxComponent } from "./chat/box/chat-box-component";
import { DimeloStateStore } from "./dimelo";
import { HeaderBannerComponent } from "./header/header-banner-component";
import { HeaderComponent } from "./header/header-component";
import { ReducedBotComponent } from "./reduced-bot/reduced-bot-component";
import { DragDropService } from "./core/drag-drop/drag-drop.service";

/** The main component of the application */
@Component({
  selector: "bot-root",
  templateUrl: "./app.component.html",
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    AsyncPipe,
    HeaderComponent,
    HeaderBannerComponent,
    ChatBoxComponent,
    ReducedBotComponent,
  ],
})
export class AppComponent implements OnInit {
  @HostBinding("class") hostClasses: string;

  // TODO test
  constructor(
    @Inject(APP_CONFIG) appConfig: AppConfig,
    private parentService: AppParentService,
    private dimeloStateStore: DimeloStateStore,
    private dragDropService: DragDropService,
    protected appStateService: AppStateService,
    protected chatOpenStore: ChatOpenStore,
    protected chatLifecycleService: ChatLifecycleService
  ) {
    this.hostClasses = `pby-bot-app pby-bot-${appConfig.botName}`;
    this.appStateService.available$
      .pipe(
        filter((available: AvailabilityState) => available === "no"),
        tap(() => this.parentService.removeBot()),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  // TODO test
  ngOnInit() {
    if (this.dimeloStateStore.isDimeloChatDisplayed) {
      this.chatLifecycleService.notifySwitchedOff();
    }
    this.appStateService.initialize();
  }
}
