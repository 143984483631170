import { Injectable } from "@angular/core";
import { EventMessage } from "./event-message.model";
import { EventBus } from "./event-bus";

/**
 * An event bus that allows communication between different parts of the application.
 *
 * It uses MessageChannel to send messages.
 * This implementation is used in the child iframe to communicate with the loader-factory.
 *
 * It waits for the init event to be received and sends messages through the event bus channel via
 * the port that has been communicated through the init event.
 */
@Injectable({ providedIn: "root" })
export class EventBusTarget implements EventBus {
  // The port used to send messages
  private _port: MessagePort | null = null;

  set port(value: MessagePort) {
    this._port = value;
  }

  // List of subscribers to the event bus
  subscribers: Array<(value: EventMessage<any>) => void> = [];

  /**
   * Waits for the init event to be received.
   *
   * Optionally, a callback can be provided to handle the init event.
   * @param callback - The callback function to be called when the init event is received.
   * @returns A promise that resolves when the init event is received.
   */
  waitForInit(callback?: (event: EventMessage<any>) => void): Promise<void> {
    return new Promise((resolve) => {
      window.addEventListener("message", (event) => {
        if (!event.ports.length) return;

        const message = event.data as EventMessage<any>;
        if (message.eventId === "init") {
          if (!this.port) {
            this.port = event.ports[0];
          }
        }

        if (callback) this.subscribe((message) => callback(message));
        resolve();
      });
    });
  }

  public sendEventMessage<T>(message: EventMessage<T>): void {
    this._port?.postMessage(message);
  }

  public subscribe<T>(callback: (value: EventMessage<T>) => void): void {
    this.subscribers.push(callback as (value: EventMessage<any>) => void);

    const handler = (event: MessageEvent) => {
      const message = event.data as EventMessage<T>;
      this.subscribers.forEach((subscriber) => subscriber(message));
    };

    if (this._port) this._port.onmessage = handler;
  }

  public closeAllChannels(): void {
    this._port?.close();
  }
}
