import { aroleConfig } from "../configs/arole.config";
import { clickrhConfig } from "../configs/clickrh.config";
import { commonConfig } from "../configs/common.config";
import { digiConfig } from "../configs/digi.config";
import { douaneConfig } from "../configs/douane.config";
import { plumeConfig } from "../configs/plume.config";
import { popConfig } from "../configs/pop.config";
import { relaConfig } from "../configs/rela.config";
import { BotName, CompilationConfig, LocalConfig } from "../models/config.model";

// Specific local config (if not defined for one bot, it will take commonLocalConfig)
export const localConfig: LocalConfig = {
  arole: aroleConfig,
  clickrh: clickrhConfig,
  digi: digiConfig,
  douane: douaneConfig,
  plume: plumeConfig,
  pop: popConfig,
  rela: relaConfig,
};

function retrieveBotNameFromUrl(): BotName {
  let params = new URL(document.URL).searchParams;

  if (document.referrer) {
    params = new URL(document.referrer).searchParams;
  }
  return (params.get("botName") as BotName) || BotName.DIGI;
}

function convertToExpectedType(config: Partial<CompilationConfig>) {
  /* Convert boolean */
  if (config) {
    for (const [key, value] of Object.entries(config)) {
      if (value === "True" || value === "true") {
        (config[key as keyof CompilationConfig] as boolean) = true;
      } else if (value === "False" || value === "false") {
        (config[key as keyof CompilationConfig] as boolean) = false;
      }
    }
  }
  return config;
}

function getCompilationConfig(botName: BotName): CompilationConfig {
  // In dev mode, the config is defined in the model, otherwise it is retrieved from docker-entrypoint
  const compilationConfig = localConfig[botName] ?? commonConfig;
  const prodCompilationConfig = convertToExpectedType(
    // Variable d'environnement en production
    (window as any)["BOT_WEB_INTERFACE_SETTINGS"]
  );

  return {
    ...compilationConfig,
    ...prodCompilationConfig,
  };
}

export function getConfig(production: boolean): CompilationConfig {
  let botName;
  // Retrieve the bot name from the environment variable in production, or from the query parameters in development
  if (!production) {
    botName = retrieveBotNameFromUrl();
  } else {
    botName = "{{BOT_NAME}}" as BotName;
  }

  // Retrieve the href from the environment variable in production, or from the window location in development
  let href = production ? "{{HREF}}" : "";

  // Specific cases for chatbot app
  if (botName.includes("BOT_NAME")) {
    botName = (window as any)["BOT_WEB_INTERFACE_SETTINGS"].botName;
  }
  if (href.includes("HREF")) {
    href = (window as any)["BOT_WEB_INTERFACE_SETTINGS"].distLocation;
  }

  return { ...getCompilationConfig(botName), production, distLocation: href };
}
