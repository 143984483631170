import { Injectable } from "@angular/core";
import { AppParentService } from "../parent";

@Injectable({
  providedIn: "root",
})
export class DragDropService {
  dragElement = document.querySelector("bot-root") as HTMLElement;

  constructor(private appParentService: AppParentService) {
    this.dragElement.addEventListener("dragstart", (event: DragEvent) => {
      this.appParentService.sendDragStartEvent({
        x: event.screenX,
        y: event.screenY,
      });
    });

    this.dragElement.addEventListener("dragend", () => {
      this.appParentService.sendDragEndEvent();
    });
  }
}
