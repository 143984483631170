import { BotName, CompilationConfig } from "../models/config.model";

export const commonConfig: CompilationConfig = {
  production: false,
  botName: BotName.AROLE,
  distLocation: "http://localhost:4200",
  iframeSandbox: false,
  botVersion: "",
  localStoragePrefix: "",
  opened: false,

  restrictedUriPatterns: ["MyTalentsoft"],

  hostDimensions: {
    openHeight: 650,
    openWidth: 450,
    closedHeight: 180,
    closedWidth: 130,
    closedSmallScreenHeight: 80,
    closedSmallScreenWidth: 80,
  },

  hasOidcSupport: false,
};
