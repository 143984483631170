import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AppConfig, APP_CONFIG } from "./config";

@Injectable({ providedIn: "root" })
export class ParentLocationUrlValue {
  private url$ = new BehaviorSubject<string>("");

  constructor(@Inject(APP_CONFIG) private config: AppConfig) {}

  /** @return The parent window URL if the application runs in an iframe or the current window URL */
  get value() {
    return this.url$.getValue();
  }

  set(value: string) {
    this.url$.next(value);
  }
}
