import { Inject, Injectable } from "@angular/core";
import { CHAT_SWITCH_SERVICE, ChatSwitchService, ChatSwitchDelayedService } from "app/chat/switch";
import { UserRightsStore } from "app/user";
import { ChatOpenStore } from "app/chat/open";
import { ChatLifecycleService } from "../chat-lifecycle.service";
import { ConversationPrefsStore } from "app/chat/prefs";
import { ScoreFormStore } from "../score-form";

/** A service to execute commands (scripts) sent by the remote bot, through incoming messages */
@Injectable({ providedIn: "root" })
export class IncomingScriptExecutor {
  actions = {
    rgpd_accepted: () => {
      this.conversationPrefsStore.setGdprAccepted(true);
      this.userRightsStore.canWrite = true;
    },
    rgpd_refused: () => (this.userRightsStore.canWrite = false),
    question_evaluation_block: () => (this.userRightsStore.canWrite = false),
    allow_user_to_write: () => (this.userRightsStore.canWrite = true),
    close_chat_with_delay: () => this.chatOpenStore.closeWithDelay(),
    clear_conversation: () => this.chatLifecycleService.reset(),
    open_dimelo_chat: () => this.chatSwitchService.switchChatOff({}),
    "PB_chat_advisor_switch();": () => this.chatSwitchDelayedService.switchChatOff({}),
    open_score_form: () => this.scoreFormStore.request(),
  };

  constructor(
    private conversationPrefsStore: ConversationPrefsStore,
    @Inject(CHAT_SWITCH_SERVICE) private chatSwitchService: ChatSwitchService,
    private chatSwitchDelayedService: ChatSwitchDelayedService,
    private userRightsStore: UserRightsStore,
    private chatOpenStore: ChatOpenStore,
    private chatLifecycleService: ChatLifecycleService,
    private scoreFormStore: ScoreFormStore
  ) {}

  /** Executes the passed incoming message, if it is supported. Otherwise, the message is ignored */
  execute(scriptId: string): void {
    if (this.actions.hasOwnProperty(scriptId)) {
      const actionKey = scriptId as keyof typeof this.actions;
      this.actions[actionKey]();
    }
  }
}
