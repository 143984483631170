import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { APP_CONFIG, AppConfig } from "./config";
import { EventBusTarget } from "./events/event-bus-target.service";
import { EventMessage } from "./events/event-message.model";
import { ParentLocationUrlValue } from "./parent-location-url.value";
import { DeviceUtil } from "./util";

export type DeviceType = "mobile" | "wide";

/**
 * A service used to store data related to the device and the browser tab.
 */
@Injectable({ providedIn: "root" })
export class AppHostService {
  private _isSmallScreen = false;
  private _isTestBot = false;
  private _isLoaderReady$ = new BehaviorSubject<boolean>(false);
  private _entrypoint$ = new BehaviorSubject<string | null>(null);

  public focusOnUserInput$ = new Subject<number | null>();

  private eventSubjects = new Map<string, Subject<unknown | null>>();

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private parentLocationUrl: ParentLocationUrlValue,
    private device: DeviceUtil,
    private eventBus: EventBusTarget
  ) {
    this.eventBus.waitForInit((message) => this.onEvent(message));
  }

  private onEvent(event: EventMessage<any>) {
    switch (event.eventId) {
      case "loaderReady": {
        this._isLoaderReady$.next(true);
        break;
      }
      case "url": {
        this.parentLocationUrl.set(event.data);
        break;
      }
      case "isTestBot": {
        this._isTestBot = !!event.data;
        break;
      }
      case "isSmallScreen": {
        this._isSmallScreen = event.data;
        break;
      }
      case "focusAfterImageClosed": {
        this.focusOnUserInput$.next(Date.now());
        break;
      }
      case "entrypoint": {
        // set starting tree node
        this._entrypoint$.next(event.data);
        break;
      }
      default: {
        // check on local storage event (get conversationID, open, conversdationPrefs etc) if external persistent store is set ie if sandbox
        if (this.eventSubjects.has(event.eventId)) {
          this.eventSubjects.get(event.eventId)?.next(event.data);
        }
      }
    }
  }

  /** @return The current browser tab URL */
  get currentUrl(): string {
    return this.parentLocationUrl.value;
  }

  /** @return Whether the application is being executed on a small screen */
  get isSmallScreen(): boolean {
    return this._isSmallScreen;
  }

  /** @return The device type on which the application is running (mobile or wide) */
  get deviceType(): DeviceType {
    return this.isMobile ? "mobile" : "wide";
  }

  /** @return Whether the application is being executed on mobile */
  get isMobile(): boolean {
    return this.device.isMobile;
  }

  /** @return Whether the current bot is a test one */
  get isTestBot(): boolean {
    console.log("get is test bot", this._isTestBot);
    return this._isTestBot;
  }

  /** @return Whether the host is ready for listening to events */
  get isLoaderReady$(): Observable<boolean> {
    return this._isLoaderReady$.asObservable();
  }

  /** @return The conversation entrypoint */
  get entrypoint$(): Observable<string | null> {
    return this._entrypoint$.asObservable();
  }

  register(eventId: string, subject: Subject<unknown | null>) {
    this.eventSubjects.set(eventId, subject);
  }
}
