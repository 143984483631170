import { HostDimensions } from "./host-dimensions.model";

export enum BotName {
  CLICKRH = "clickrh",
  DIGI = "digi",
  DOUANE = "douane",
  PLUME = "plume",
  POP = "pop",
  AROLE = "arole",
  RELA = "rela",
}

export interface CompilationConfig {
  production: boolean;

  /** botName but can be overwritten with url in dev mode*/
  botName: BotName;
  localStoragePrefix: string;
  distLocation: string;
  iframeSandbox: boolean;
  botVersion: string;

  /** List of restricted uri patterns */
  restrictedUriPatterns: string[];

  opened: boolean;
  hostDimensions: HostDimensions;
  hasOidcSupport: boolean;
}

export type LocalConfig = { [key in BotName]: CompilationConfig };
